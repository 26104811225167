import React from "react";
import "./index.css";
import FounderImg from "../../../assets/founder.png";
import { useTranslation } from "react-i18next";

const Founder = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-20 lg:p-20 p-10 container-founder">
      <h4 className="pb-16">{t("about.founder.heading")}</h4>
      <div className="flex lg:flex-row flex-col pb-5 ">
        <div className="founder-image lg:mb-0 mb-10">
          <img
            src={FounderImg}
            alt="Founder"
            width={269}
            height={241}
            className="founder-image"
          />
        </div>
        <div className="founder-description lg:pl-12 lg:pr-40">
          <h5 className="founder-title pb-7">{t("about.founder.title")}</h5>
          <div className="founder-story text-justify">
            {t("about.founder.subtext")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Founder;
