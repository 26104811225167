import React from "react";
import { useTranslation, Trans } from "react-i18next";
import Founder from "../components/About/Founder";
import HatioAdvantage from "../components/About/HatioAdvantage";
import Info from "../components/common/Info";
import SEO from "../components/common/seo";
import Footer from "../components/home/Footer";
import Header from "../components/home/Header";
import TeamPic from "../assets/team_pic.png";
import "./about.css";
import "../i18n";

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <SEO
        props={{
          title: t("about.seoTitle"),
          path: "/about",
        }}
      />
      <Header />
      <div className="container mx-auto about-banner">
        <div className="flex items-center justify-center flex-col container-content mx-auto">
          <h2 className="about-heading text-neutral-oxford-blue">
            <Trans i18nKey="about.bannerHeading">
              We build technology for <span>BillDesk</span>,
            </Trans>
            <p className="!mb-0 subtitle text-[20px] md:text-3xl">
              India's largest Payment Aggregator and Bill Payment Platform
            </p>
          </h2>
          <p className="body-text-1">{t("about.paraAbout")}</p>
        </div>
      </div>
      <div className="pt-28 container mx-auto about-section">
        <div className="flex flex-row justify-center items-center lg:pb-0 pb-10">
          {/* <TeamPic className="h-full w-full" /> */}
          <img src={TeamPic} alt="Team" className="h-full w-full rounded" />
        </div>
        <Info title={t("about.infoText")} />
        <Founder />
      </div>
      <Footer />
    </>
  );
};

export default About;
